<template>
  <div class="pages">
    <!--顶部头像-->
    <div class="headerInfo">
      <img class="headBack" src="../static/images/main/banner_null.png" width="100%"/>
      <div class="backView">
        <span class="Back" @click="back()">返回</span>
        <span class="Record" @click="toRecord()">训练记录</span>
      </div>
      <div class="userInfo">
        <img v-if="user.Sex == '男'" :src="user.Head != ''?user.Head:headImgUrl" class="round_icon"
             :style="{border:'solid 2px #AACC03'}">
        <img v-if="user.Sex == '女'" :src="user.Head != ''?user.Head:headImgUrl" class="round_icon"
             :style="{border:'solid 2px #E75296'}">
        <span>{{ user.Name }}</span>
      </div>
    </div>
    <div class="title">
      <em>日常训练</em>
    </div>
    <div class="tabs">
      <div class="dayInfo" v-for="(day,i) in weeks" @click="changeActive(day.disable,i)">
        <div :class="[{'disableTab':day.disable}]">
          <div :class="[{'tab':true},{'activeTab':i == active}]">
            周{{ day.name.substring(2, 3) }}
          </div>
          <div class="dayDate">
            <span>{{ day.dayDate }}</span><br>
            {{ day.monthDate }}月
          </div>
        </div>
      </div>
    </div>

    <div class="topTitle">
      <span>名称</span>
      <span>训练要求</span>
    </div>

    <div class="recordEmpty" v-if="trainingMotionList == null">
      当天暂无训练计划
    </div>
    <div class="trainingDetail" v-for="item in trainingMotionList">

      <ul>
        <li>
          <div>{{item.VTitle}}</div>
          <div>{{item.TrName}}</div>
        </li>

      </ul>
    </div>
    <!--底部课程统计-->
    <bottomTab :curTab="thisTab"/>
  </div>
</template>

<script>
import axios from 'axios';
import bottomTab from '../components/bottomTab'
import {
  VipUserSelfQuery,
  MyDailyTrainDetailListQuery
} from '../api/getApiRes.js'

let qs = require('qs');
import Global from '../Global.js'
// 引入基本模板
let echarts = require('echarts/lib/echarts')
// 引入柱状图组件
require('echarts/lib/chart/bar')
require('echarts/lib/chart/line')
// 引入提示框和title组件
require('echarts/lib/component/title')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/dataZoom')
require('echarts/lib/component/legend')
require('echarts/lib/component/grid')

export default {
  data() {
    return {
      active: 0,
      weeks: [],
      today: '',
      viewType: '',//控制该返回哪一个页面
      dtoId: '',
      userMd5: '',
      thisTab: '日常训练',
      screenWidth: document.body.clientWidth + 'px',
      headImgUrl: require('../static/images/mine/header_default.png'),
      trainingMotionList: [],
      classFinish: 0,
      classTotal: 0,
      user: {
        Name: '',
        Age: '',
        Height: '',
        Weight: '',
        StaticHr: '',
        Phone: '',
        Sex: '',
        Head: '',
      }
    }
  },
  watch: {
    '$route'(to) {
      if (to.name == 'dailyTrainingDetail') {
        let that = this;
        that.active = 0;
        that.dtoId = this.$route.query.dtoId;
        that.viewType = that.$route.query.viewType;
        that.getUserInfo();//获取用户基本信息
        that.getFurtherDays(7);//加载七天的日历
      }
    },
  },
  mounted() {
    let that = this;
    that.active = 0;
    that.dtoId = this.$route.query.dtoId;
    that.viewType = that.$route.query.viewType;
    that.getUserInfo();//获取用户基本信息
    that.getFurtherDays(7);//加载七天的日历
  },
  methods: {
    //返回上一页
    back() {
      if (this.viewType == 1) {
        this.$router.push({path: '/report'});
      } else {
        this.$router.push({path: '/dailyTrainingList'});
      }
    },
    //返回到结果集
    toRecord() {
      this.$router.push({path: '/dailyTrainingList'});
    },
    changeActive(disable, i) {
      if (disable) {
        return;
      }
      this.active = i;
      console.log(this.weeks[i].orderDate);
      this.getTrainingDetail();
    },
    //获取用户基本信息
    getUserInfo() {
      let that = this;
      let param = {
        token: localStorage.token
      };
      let postData = qs.stringify(param);
      VipUserSelfQuery(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.user = json.Rs;
          that.userMd5 = json.Rs.UserMd5;
        } else {
          that.$toast.message(json.Memo);
        }
      })
    },
    // 获取未来7天的
    getFurtherDays(HaveDays) {
      let now = new Date(this.$route.query.beginDate);
      let nowTime = now.getTime();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let days = '';
      let item = [];
      let month = 0;
      let day = 0;
      let disable = false;
      this.weeks = [];
      for (let i = 0; i < 7; i++) {//共显示7天,没课表的灰掉
        days = new Date(nowTime + (i) * oneDayTime);//显示周日
        month = days.getMonth() + 1;
        day = days.getDate();
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        if (i >= HaveDays) {
          disable = true;
        }
        item = {
          name: this.numberToWeek(days.getDay()),
          data: days.getMonth() + 1 + '月' + days.getDate() + '日',
          orderDate: days.getFullYear() + '-' + month + '-' + day,
          day: this.numberToWeek(days.getDay()),
          monthDate: days.getMonth() + 1,
          dayDate: days.getDate(),
          disable: disable
        };
        this.weeks.push(item);
      }
      this.today = this.weeks[0].name;
      this.getTrainingDetail();
    },
    numberToWeek(val) {
      switch (parseInt(val)) {
        case 1:
          return '星期一';
          break;
        case 2:
          return '星期二';
          break;
        case 3:
          return '星期三';
          break;
        case 4:
          return '星期四';
          break;
        case 5:
          return '星期五';
          break;
        case 6:
          return '星期六';
          break;
        case 0:
          return '星期天';
          break;
      }
    },
    //获取课程评分信息
    getTrainingDetail() {
      let that = this;
      let param = {
        token: localStorage.token,
        dtoId: that.dtoId,
        inComingDay:this.weeks[this.active].orderDate
      };
      let postData = qs.stringify(param);
      MyDailyTrainDetailListQuery(postData).then(res => {
        let json = res;
        if (json.Code == 0) {
          that.trainingMotionList = json.Rs;
        }else{
          that.trainingMotionList = [];
        }
      })
    },
  },
  filters: {
    hidePhone: function (val) {
      let str = String(val)
      let len = str.length;
      if (len >= 7) {
        let reg = str.slice(-8, -4)
        return str.replace(reg, "****")
      } else if (len < 7 && len >= 6) {
        let reg = str.slice(-4, -2)
        return str.replace(reg, "**")
      }
    },
    filterTime: function (value) {
      if (!value) {
        return 0;
      } else {
        return dateFormatWithoutZone(value, 16);
      }
    },
    fmtTime: function (value) {
      if (!value) {
        return 0
      } else {
        // js默认使用毫秒
        value = value * 1000
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + '-' + MM + '-' + d;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // A跳转到B，B页面停留在A页面的滚动位置；解决方法：将scrollTop设置为0
    next(vm => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (to.name == 'reportLessonDetail') {
        vm.getUserInfo();
      }
    });
  },
  components: {
    bottomTab
  }
}
</script>

<style scoped>
.pages {
  overflow: hidden;
  display: block;
  margin: 0 auto;
  background: #F2F2F2;
}

.headerInfo {
  position: relative;
}

.headerInfo .headBack {
  height: 120px;
  position: absolute;
  z-index: 1;
}

.headerInfo .backView {
  left: 5%;
  width: 90%;
  top: 1.5rem;
  position: absolute;
  z-index: 2;
  text-align: center;
}

.headerInfo .Back {
  width: 50px;
  height: 25px;
  font-size: 14px;
  border-radius: 2px;
  line-height: 26px;
  float: left;
  color: #F4F4F4;
  display: block;
  margin-top: 60px;
  background: rgba(255, 255, 255, 0.24);
  text-align: center;
}

.headerInfo .Record {
  width: 70px;
  height: 25px;
  float: right;
  font-size: 14px;
  border-radius: 2px;
  line-height: 26px;
  color: #FFE58D;
  display: block;
  margin-top: 60px;
  margin-left: 110px;
  background: rgba(255, 255, 255, 0.24);
  text-align: center;
}

.headerInfo .userInfo {
  left: 42%;
  top: 1.25rem;
  position: absolute;
  z-index: 2;
  text-align: center;
}

.headerInfo .userInfo span {
  font-size: 16px;
  color: white;
  display: block;
  margin-top: 5px;
}

.round_icon {
  width: 64px;
  height: 64px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 3px;
  border: solid 2px white;
}

.title {
  padding-top: 10px;
  margin-top: 130px;
  background: #ffffff;
  font-size: 18px;
  text-align: center;
}

.tabs {
  width: 100%;
  padding-left: 5px;
  height: 130px;
  background: #ffffff;
  justify-content: space-between;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
}


.tab {
  font-size: 12px;
  background: #F4F4F4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;
  border: none;
  outline: none;
  color: inherit;
  position: relative;
  -webkit-transition: all .45s cubic-bezier(.445, .05, .55, .95);
  transition: all .45s cubic-bezier(.445, .05, .55, .95);
  width: 54px;
  margin-right: 3px;
  margin-left: 3px;
  float: left;
  height: 22px;
  cursor: pointer;
  line-height: 24px;
  text-align: center;
  margin-top: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tab.activeTab {
  color: #fff;
  background-color: #E75296;
  color: hsla(0, 0%, 100%, .7);
}

.dayInfo {
  max-width: 57px;
}

.dayInfo .disableTab {
  color: #D6D6D6;
}

.dayDate {
  float: left;
  width: 54px;
  height: 60px;
  background: #f4f4f4;
  font-size: 12px;
  margin-left: 3px;
  margin-top: 1px;
  margin-right: 3px;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.classSelect .activeClass {
  background-color: #E75296;
  color: white;
}

.dayDate span {
  font-size: 22px;
}

.topTitle {
  height: 60px;
}

.topTitle span {
  background: #ffffff;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  width: 50%;
  margin-top: 10px;
  text-align: center;
  display: block;
  float: left;
}


.recordEmpty {
  display: block;
  height: 200px;
  line-height: 200px;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
}

.trainingDetail ul {
  background: #F2F2F2;
  width: 100%;
}

.trainingDetail ul li {
  width: 100%;
  display: block;
  overflow: auto;
  margin-top: 2px;
  background: #ffffff;
}

.trainingDetail ul li div {
  font-size: 14px;
  width: 50%;
  border-right: 1px solid #f2f2f2;
  padding-top: 5%;
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: 20px;
  float: left;
}

.value {
  width: 100%;
  height: 131px;
  background: #fff;
  padding: 13px 0;
}


.value .vTitle {
  width: 100%;
  display: block;
  text-align: left;
  margin-left: 25px;
  font-family: "PingFang SC";
  font-weight: 300;
  font-size: 15px;
}

.value .expire {
  text-align: center;
  font-size: 14px;
  display: block;
  color: #4E4E4E;
}

.vTitle.left {
  width: 30%;
  margin-top: 12px;
  color: #E75296;
  float: left;
}

.vTitle.left span {
  font-size: 22px;
  font-weight: bold;
  color: #E75296;
}

.vTitle.right {
  width: 30%;
  margin-top: 12px;
  color: #37CB00;
  float: right;
}

.vTitle.right span {
  color: black;
  font-size: 22px;
  font-weight: bold;
  color: #37CB00;
}


/deep/ .linear-progress {
  width: 90%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  border-radius: 250px;
}

.fitnessRecord .record {
  padding-bottom: 15px;
  padding-top: 5px;
  background: #FFFFFF;
}

.fitnessRecord .record li {
  background: #F7FFF4;
  height: 56px;
  width: 95%;
  margin: 10px auto 0;
  border-radius: 8px;
  border: 1px solid #DDEFD6;
}

.record li .column1 {
  float: left;
}

.record li .column1 i {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 100%;
  background: #37CB00;
  margin-left: 10px;
  margin-top: 20px;
}

.record li .column2 {
  float: left;
  height: 56px;
  margin-left: 10px;
  display: block;
}

.record li .column2 span {
  margin-top: 8px;
  display: block;
  font-size: 14px;
}

.record li .column2 em {
  font-size: 12px;
  color: #909090;
}

.record li .column3 {
  float: right;
  height: 56px;
  margin-right: 20px;
  display: block;
}

.record li .column3 img {
  margin-top: 12px;
  margin-left: 3px;
}


/deep/ .mu-warning-text-color {
  float: left;
}

@media only screen and (max-width: 640px) {

}

@media only screen and (max-width: 480px) {

}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 360px) {
}

@media only screen and (max-width: 320px) {

}

@media only screen and (min-width: 641px) {

}
</style>
